import React, { useState, useEffect } from "react";
import LoginPage from "./component/login/login";
import Dashboard from "./component/dashboard/Dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Jitsiapi from "./pages/Jitsiapi";
const App = () => {
  
  return (
    <div>
     

      <Router>
      <Routes>
        {/* Define the LoginPage route */}
        <Route path="/" element={<LoginPage />} />

        {/* Define the Dashboard route */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/Jitsiapi" element={<Jitsiapi/>} />

      </Routes>
    </Router>
    </div>
  );
};

export default App;