import React, { useState, useEffect } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
const { GoogleGenerativeAI } = require("@google/generative-ai");

const RealTimeTranscription = ({ api, userName }) => {
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const [messages, setMessages] = useState([]);
  const [analysis, setAnalysis] = useState(null);
  const [debouncedTranscript, setDebouncedTranscript] = useState("");
  const [popupMessage, setPopupMessage] = useState(null);
  const debounceDelay = 3000; 

  const genAi = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API);
  const model = genAi.getGenerativeModel({
    model: "gemini-1.5-pro",
  });

  useEffect(() => {
    if (api) {
      api.addListener("incomingMessage", (msg) => {
        const senderName = api.getParticipantById(msg.id)?.getDisplayName() || "Unknown";
        setMessages((prev) => [...prev, { sender: senderName, text: msg.message }]);
      });
    }
  }, [api]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTranscript(transcript);
    }, debounceDelay);

    return () => clearTimeout(handler); 
  }, [transcript]);

  useEffect(() => {
    if (debouncedTranscript) {
      analyzeConversation(debouncedTranscript);
    }
  }, [debouncedTranscript]);

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const analyzeConversation = async (text) => {
    if (!text) return;

    const promptTemplate = `
      Please diarize the below conversation between two speakers (consumer and customer):
      1. Diarize the conversation.
      2. Based on the diarized conversation, would the customer buy the product? Provide 'Yes' or 'No' as the response.
    `;

    const prompt = `${promptTemplate} Conversation: ${text}`;

    try {
      const response = await model.generateContent(prompt);
      const result = response.response.text();
      setAnalysis(result);
      handlePopup(result);
      console.log("Analysis Result:", result);
    } catch (error) {
      console.error("Error generating content:", error);
      setAnalysis("Error analyzing sentiment.");
    }
  };

  const handlePopup = (result) => {
    if (result.includes("Yes")) {
      setPopupMessage("The call is transferring to the executive.");
    } else if (result.includes("No")) {
      setPopupMessage("The call is adding to the next queue.");
    } else {
      setPopupMessage(null);
    }
  };

  const handleSendTranscript = () => {
    if (api && transcript) {
      api.executeCommand("sendEndpointTextMessage", "", transcript);
      setMessages((prev) => [...prev, { sender: userName, text: transcript }]);
      resetTranscript();
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <p className="text-center text-red-500">Your browser doesn't support speech recognition.</p>;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center p-6">
      {/* Header */}
      <header className="w-full bg-black text-white py-4 px-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold text-center">CCAI Meeting Transcription</h1>
      </header>

      {/* Main Content */}
      <div className="w-full max-w-4xl bg-white rounded-lg shadow p-6 mt-6">
        {/* Listening Status */}
        <div className="flex items-center justify-between mb-4">
          <p className="text-gray-700 text-lg">
            Listening: <span className={`font-bold ${listening ? "text-green-500" : "text-red-500"}`}>
              {listening ? "Yes" : "No"}
            </span>
          </p>
          <button
            onClick={handleStartListening}
            className="bg-black text-white py-2 px-4 rounded "
          >
            Start Listening
          </button>
        </div>

        {/* Transcript Section */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Your Transcript:</h2>
          <div className="bg-gray-100 p-4 rounded shadow-inner min-h-[100px] text-gray-800">
            {transcript || "No transcript available yet."}
          </div>
        </div>

        {/* Analysis Section */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Analysis:</h2>
          <div className="bg-gray-100 p-4 rounded shadow-inner text-gray-800">
            {analysis || "No analysis yet."}
          </div>
        </div>

        {/* Messages
        <div>
          <h2 className="text-xl font-semibold mb-2">Messages:</h2>
          <ul className="list-disc pl-6 text-gray-700">
            {messages.map((msg, index) => (
              <li key={index}>
                <b>{msg.sender}:</b> {msg.text}
              </li>
            ))}
          </ul>
        </div> */}
      </div>

      {/* Popup */}
      {popupMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold mb-4">{popupMessage}</p>
            <button
              onClick={() => setPopupMessage(null)}
              className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RealTimeTranscription;
