import React, { useEffect } from "react";

const JitsiMeet = () => {
  useEffect(() => {
    const domain = process.env.REACT_APP_JITSI_DOMAIN; 
    const options = {
      roomName: "SupportRoom",
      width: "100%",
      height: "100%",
      parentNode: document.getElementById("jitsi-container"),
      userInfo: {
        displayName: "User",
      },
    };

    const api = new window.JitsiMeetExternalAPI(domain, options);

    return () => {
      api.dispose(); 
    };
  }, []);

  return (
    <div className="h-screen flex flex-col">
      {/* Navigation Bar */}
      {/* <header className="bg text-white flex items-center justify-between px-6 py-3 shadow">
        <h1 className="text-xl font-bold">CCAI</h1>
      </header> */}

      {/* Full-Screen Meeting Container */}
      <div
        id="jitsi-container"
        className="flex-1 bg-gray-900"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default JitsiMeet;
