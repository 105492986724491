import React, { useState, useEffect } from "react";
import {
  BiSolidDashboard,
  BiPhone,
  BiUser,
  BiCog,
  BiLogOut,
} from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom"; // Import useNavigate
const Dashboard = () => {
  const [username, setUsername] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCRMType, setSelectedCRMType] = useState("All");
  const navigate = useNavigate();
  const dummyData = [
    {
      id: 1,
      name: "John Anderson",
      callStarted: "2024-12-08 9:30:29",
      callEnded: "2024-12-08 9:45:41",
      status: "Completed",
      comments: "Discussed project timelines, including setting realistic goals and milestones.",
      jitsiEndpoint: "https://meet.jit.si/JohnMeeting-1234",
      email: "johnanderson25@gmail.com",
      crmType: "Support CRM",
    },
    {
      id: 2,
      name: "Thomas Clark",
      callStarted: "2024-12-10 10:03:12",
      callEnded: "2024-12-10 10:12:25",
      status: "Completed",
      comments: "Reviewed client requirements and discussed key deliverables for Q1.",
      jitsiEndpoint: "https://meet.jit.si/ThomasReview-5678",
      email: "thclark56@gmail.com",
      crmType: "Sales CRM",
    },
    {
      id: 3,
      name: "Emily Thompson",
      callStarted: "2024-12-11 12:17:45",
      callEnded: "2024-12-11 12:22:38",
      status: "Completed",
      comments: "Strategized marketing content for upcoming campaigns.",
      jitsiEndpoint: "https://meet.jit.si/EmilyCampaign-91011",
      email: "emithompson857@gmail.com",
      crmType: "Marketing CRM",
    },
    {
      id: 4,
      name: "Smith Williams",
      callStarted: "2024-12-12 09:12:33",
      callEnded: "",
      status: "In-progress",
      comments: "Discussing ad creatives and reviewing draft designs for social platforms.",
      jitsiEndpoint: "https://meet.jit.si/SmithAds-121314",
      email: "smwilliams199@gmail.com",
      crmType: "Support CRM",
    },
    {
      id: 5,
      name: "Davis Taylor",
      callStarted: "2024-12-12 11:53:42",
      callEnded: "",
      status: "In-progress",
      comments: "Drafting sales strategies for achieving next quarter targets.",
      jitsiEndpoint: "https://meet.jit.si/DavisGoals-151617",
      email: "davetaylor202@gmail.com",
      crmType: "Sales CRM",
    },
    {
      id: 6,
      name: "Martin White",
      callStarted: "",
      callEnded: "",
      status: "Open",
      comments: "Preparing product launch material for stakeholder review.",
      jitsiEndpoint: "https://meet.jit.si/MartinLaunch-181920",
      email: "marwhite56352@gmail..com",
      crmType: "Marketing CRM",
    },
    {
      id: 7,
      name: "Julian Harris",
      callStarted: "",
      callEnded: "",
      status: "Open",
      comments: "Brainstorming ideas for influencer partnerships in the upcoming campaign.",
      jitsiEndpoint: "https://meet.jit.si/JulianCampaign-212223",
      email: "jullyharris1454@gmail.com",
      crmType: "Sales CRM",
    },
    {
      id: 8,
      name: "Charlie Roberts",
      callStarted: "",
      callEnded: "",
      status: "Open",
      comments: "Finalizing creative assets for the digital marketing push.",
      jitsiEndpoint: "https://meet.jit.si/CharlieAssets-242526",
      email: "charlieroberts5833@gmail.com",
      crmType: "Marketing CRM",
    },
  ];

  const handleRetrieveData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Simulating API call
      await new Promise((resolve) => setTimeout(resolve, 1500));
      const filteredData =
        selectedCRMType === "All"
          ? dummyData
          : dummyData.filter((item) => item.crmType === selectedCRMType);
      setData(filteredData);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("username");
    navigate("/"); // Redirect to the home page
  };

  useEffect(() => {
    // Retrieve the username from localStorage
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`${isSidebarOpen ? "w-64" : "w-20"
          } bg-gray-800 text-white transition-all duration-300 ease-in-out flex flex-col`}
        aria-label="Sidebar"
      >
        <div className="p-4">
          <div className="flex items-center justify-between">
            {isSidebarOpen && <h2 className="text-xl font-bold">Dashboard</h2>}
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded hover:bg-gray-700 transition-colors"
              aria-label="Toggle sidebar"
            >
              <FiMenu size={24} />
            </button>
          </div>
        </div>
        <nav className="mt-4 flex-grow">
          <div className="px-4 py-2 text-gray-400 uppercase text-sm">
            {isSidebarOpen && "Call Room"}
          </div>
          <ul className="space-y-2 px-2">
            <button
              //   onClick={handleLogout}
              className="flex items-center w-full p-2 rounded hover:bg-gray-700 transition-colors"
              aria-label="Dashboard"
            >
              <BiSolidDashboard size={24} />
              {isSidebarOpen && <span className="ml-3">Dashboard</span>}
            </button>
          </ul>
        </nav>

        {/* Profile and Logout buttons at the bottom */}
        <div className="mt-auto px-3">
          <button
              onClick={handleLogout}
              className="flex items-center w-full p-2 rounded transition-colors"
              aria-label="Logout"
            >
              <BiLogOut size={24} />
              {isSidebarOpen && <span className="ml-3">Logout</span>}
            </button>
          <li className="flex items-center w-full p-2 rounded transition-colors">
            {isSidebarOpen && <BiUser size={24} />}
            {isSidebarOpen && <span className="ml-3">{username}</span>}
          </li>
        </div>
      </aside>


      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Navbar */}
        <header className="bg-white shadow">
          <div className="flex items-center justify-between px-6 py-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              CCAI Call Dashboard
            </h1>
            {/* <div className="relative">
              <select
                className="appearance-none bg-white border border-gray-300 rounded-md py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-blue-500"
                aria-label="CRM menu"
              >
                <option>Customer Management</option>
                <option>Tasks</option>
                <option>Reports</option>
              </select>
            </div> */}
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-1 overflow-auto p-6">
          <div className="mb-6 flex items-center gap-4">
            <select
              value={selectedCRMType}
              onChange={(e) => setSelectedCRMType(e.target.value)}
              className="appearance-none bg-white border border-gray-300 rounded-md py-2 px-4 leading-tight focus:outline-none focus:border-blue-500"
              aria-label="Select CRM Type"
            >
              <option value="All">All CRM Types</option>
              <option value="Sales CRM">Sales CRM</option>
              <option value="Support CRM">Support CRM</option>
              <option value="Marketing CRM">Marketing CRM</option>
            </select>

            <button
              onClick={handleRetrieveData}
              disabled={isLoading}
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50"
              aria-label="Retrieve data"
            >
              {isLoading ? (
                <span className="flex items-center">
                  <AiOutlineLoading3Quarters className="animate-spin mr-2" />
                  Loading...
                </span>
              ) : (
                "Retrieve"
              )}
            </button>
          </div>

          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4"
              role="alert"
            >
              {error}
            </div>
          )}

          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "ID",
                      "Name",
                      "Call Started",
                      "Call Ended",
                      "Status",
                      "Comments",
                      "Jitsi Endpoint",
                      "Email",
                      "Call",
                    ].map((header, index) => (
                      <th
                        key={index}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((row) => (
                    <tr
                      key={row.id}
                      className="hover:bg-gray-50 transition-colors"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">{row.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {row.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {row.callStarted}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {row.callEnded}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {row.status === "Completed" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {row.status}
                          </span>
                        )}
                        {row.status === "In-progress" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            {row.status}
                          </span>
                        )}
                        {row.status === "Open" && (
                          <span className="px-6 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                            {row.status}
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {row.comments}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {row.jitsiEndpoint}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {row.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() => navigate("/Jitsiapi")}
                          className="text-blue-600 hover:text-blue-800 focus:outline-none"
                          aria-label={`Call ${row.email}`}
                        >
                          <BiPhone size={24} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
